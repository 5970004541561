export default {
  data() {
    return {
      c4: [
        {
          name: 'M m',
          src:
            'https://tse2-mm.cn.bing.net/th/id/OIP.CkfQ7vGNFfsvUGjMdBYyWgHaEv?pid=ImgDet&rs=1'
        },
        {
          name: 'monkey',
          src: 'https://idre.am/wp-content/uploads/2015/11/monkey.jpg'
        },
        {
          name: 'milk',
          src: 'http://i6.qhimg.com/t01382555bd03eb5a67.jpg'
        },
        {
          name: 'mittens',
          src:
            'https://i.etsystatic.com/8664326/r/il/dc9a37/2114394473/il_794xN.2114394473_482r.jpg'
        },
        {
          name: 'mommy',
          src:
            'http://5b0988e595225.cdn.sohucs.com/images/20181016/70211906550446babfc9ed7da199f34b.jpeg'
        },
        {
          name: 'apple',
          src: 'https://p1.ssl.qhmsg.com/t01e951e7ad36ca64ec.jpg'
        },
        {
          name: 'orange',
          src:
            'https://www.pngpix.com/wp-content/uploads/2016/03/Orange-and-Half-of-Orange-PNG-image-500x383.png'
        },
        {
          name: 'strawberry',
          src:
            'https://pic3.zhimg.com/v2-55ee0e5586a641e73f1b3a1e46ef54e0_r.jpg?source=1940ef5c'
        },
        {
          name: 'grapes',
          src: 'https://www.xiantao.com/uploads/allimg/180823/3-1PR3110044.png'
        }
      ]
    }
  },
  methods: {
    setC4() {
      this.list = this.c4
      this.title = 'M and fruits'
      this.currentIndex = 0
    }
  }
}
