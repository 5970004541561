export default {
  data() {
    return {
      c5: [
        {
          name: 'N n',
          src:
            'http://ferragamo-outlet.org/wp-content/uploads/2017/10/how-to-draw-bubble-writing-real-easy-letter-n-youtube-throughout-bubble-letter-n.jpg'
        },
        {
          name: 'noodles',
          src:
            'https://greenbowl2soul.com/wp-content/uploads/2020/06/Easy-Chili-Garlic-Noodles_3.jpg'
        },
        {
          name: 'nine',
          src: 'https://clipground.com/images/nine-clipart-5.jpg'
        },
        {
          name: 'nose',
          src: 'https://pngimg.com/uploads/nose/nose_PNG27.png'
        },
        {
          name: 'numbers',
          src:
            'https://www.activityshelter.com/wp-content/uploads/2016/12/pictures-of-numbers-1-10-happy.jpg'
        },
        {
          name: 'pear',
          src: 'http://pic37.nipic.com/20140109/15204857_162005310151_2.jpg'
        }
      ]
    }
  },
  methods: {
    setC5() {
      this.list = this.c5
      this.title = 'N and fruits'
      this.currentIndex = 0
    }
  }
}
