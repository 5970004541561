export default {
  data() {
    return {
      colors: [
        {
          name: 'red',
          src: null,
          bg: 'red'
        },
        {
          name: 'yellow`',
          src: null,
          bg: 'yellow'
        },
        {
          name: 'blue',
          src: null,
          bg: 'blue'
        },
        {
          name: 'white',
          src: null,
          bg: 'white'
        },
        {
          name: 'black',
          src: null,
          bg: 'black'
        },
        {
          name: 'pink',
          src: null,
          bg: 'pink'
        },
        {
          name: 'purple',
          src: null,
          bg: 'purple'
        },
        {
          name: 'brown',
          src: null,
          bg: 'brown'
        },
        {
          name: 'orange',
          src: null,
          bg: 'orange'
        }
      ]
    }
  },
  methods: {
    setC0() {
      this.list = this.colors
      this.title = 'Colors'
      this.currentIndex = 0
    }
  }
}
