export default {
  data() {
    return {
      c3: [
        {
          name: 'firecrackers',
          src: require('@/assets/chapters/c3/firecrackers.gif')
        },
        {
          name: 'red packets',
          src: require('@/assets/chapters/c3/red-packet.png')
        },
        {
          name: 'fireworks',
          src: require('@/assets/chapters/c3/fireworks.gif')
        },
        {
          name: 'sparklers',
          src: require('@/assets/chapters/c3/sparklers.gif')
        },
        {
          name: 'lanterns',
          src: require('@/assets/chapters/c3/lanterns.gif')
        }
      ]
    }
  },
  methods: {
    setC3() {
      this.list = this.c3
      this.title = 'Spring festival'
      this.currentIndex = 0
    }
  }
}
