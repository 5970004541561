export default {
  data() {
    return {
      ks: [
        {
          name: 'K',
          src: require('@/assets/chapters/c2/k.jpg')
        },
        {
          name: 'Kangaroo',
          src: require('@/assets/chapters/c2/kangaroo.jpg')
        },
        {
          name: 'Kiss',
          src: require('@/assets/chapters/c2/kiss.jpg')
        },
        {
          name: 'kitten',
          src: require('@/assets/chapters/c2/kitten.jpg')
        },
        {
          name: 'Kite',
          src: require('@/assets/chapters/c2/kite.jpg')
        }
      ]
    }
  },
  methods: {
    setC2() {
      this.list = this.ks
      this.title = 'K'
      this.currentIndex = 0
    }
  }
}
