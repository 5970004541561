<template>
  <div>
    <div class="flex response">
      <el-card class="text-xxl text-left bg-gray light">
        <div class="flex flex-direction">
          <div class="padding-bottom-sm" @click="setC0">Colors</div>
          <div class="padding-bottom-sm" @click="setC1">J & Family</div>
          <div class="padding-bottom-sm" @click="setC2">K</div>
          <div class="padding-bottom-sm" @click="setC3">Spring festival</div>
          <div class="padding-bottom-sm" @click="setC4">M and fruits</div>
          <div class="padding-bottom-sm" @click="setC5">N and fruits</div>
          <div class="padding-bottom-sm" @click="setC6">U V W X</div>
        </div>
      </el-card>
      <div class="flex-sub">
        <el-card>
          <el-carousel
            ref="cards"
            arrow="always"
            :autoplay="false"
            height="600px"
            indicator-position="none"
            @change="change"
          >
            <el-carousel-item v-for="(item, index) in list" :key="index">
              <div class="relative" style="padding-bottom: 190px">
                <el-image
                  v-if="item.src"
                  :src="item.src"
                  fit="contain"
                  class="response"
                  style="height: 600px"
                />
                <div
                  v-if="!item.src && item.bg"
                  style="height: 600px"
                  :class="`bg-${item.bg}`"
                ></div>
              </div>
            </el-carousel-item>
          </el-carousel>

          <div class="padding-bottom bg-white">
            <div class="text-red text-sl text-bold">
              {{ current.name }}
            </div>
            <audio controls :src="audioSource" />
            <div class="flex align-center justify-center padding-top-xl">
              <div class="flex flex-wrap text-center">
                <div
                  v-for="(item, index) in list"
                  :key="index"
                  class="margin-right padding-xs padding-lr-sm radius btn"
                  :class="index === currentIndex ? 'bg-blue ' : 'bg-gray'"
                  @click="changeCard(index)"
                >
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <div class="text-gray text-sm padding text-center" @click="gotoAbout">
      About
    </div>
  </div>
</template>

<script>
import C0Color from './capters/c0-color'
import C1Family from './capters/c1-family'
import C2Ks from './capters/c2-ks'
import C3Spring from './capters/c3-spring'
import C4MFruit from './capters/c4-m-fruit'
import C5N from './capters/c5-n-fruit'
import C6 from './capters/c6-uvwx'

export default {
  mixins: [C0Color, C1Family, C2Ks, C3Spring, C4MFruit, C5N, C6],
  data() {
    return {
      currentIndex: 0,
      list: []
    }
  },
  computed: {
    current() {
      const item = this.list[this.currentIndex]
      return item
    },
    audioSource() {
      return `http://dict.youdao.com/dictvoice?type=0&audio=${this.current.name}`
    }
  },
  created() {
    this.list = this.colors
    this.title = 'Chapters'
  },
  methods: {
    change(index) {
      this.currentIndex = index
    },
    gotoAbout() {
      this.$router.push('about')
    },
    changeCard(index) {
      this.$refs.cards.setActiveItem(index)
    }
  }
}
</script>

<style scoped>
</style>
