export default {
  data() {
    return {
      family: [
        {
          name: 'Mommy and Baby',
          src: require('@/assets/chapters/c1/mummy.jpg')
        },
        {
          name: 'Daddy & Baby',
          src: require('@/assets/chapters/c1/daddy.jpg')
        },
        {
          name: 'Brother',
          src: require('@/assets/chapters/c1/brother.jpg')
        },
        {
          name: 'Sister',
          src: require('@/assets/chapters/c1/sister.jpg')
        },
        {
          name: 'Grandpa',
          src: require('@/assets/chapters/c1/grandpa.jpg')
        },
        {
          name: 'Grandma',
          src: require('@/assets/chapters/c1/grandma.jpg')
        }
      ]
    }
  },
  methods: {
    setC1() {
      this.list = this.family
      this.title = 'Family'
      this.currentIndex = 0
    }
  }
}
