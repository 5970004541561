export default {
  data() {
    return {
      c6: [
        {
          name: 'violin',
          src:
            'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1647794327,3985880866&fm=26&gp=0.jpg'
        },
        {
          name: 'vase',
          src:
            'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=41978662,1456374607&fm=26&gp=0.jpg'
        },
        {
          name: 'vegetable',
          src:
            'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=1648975544,3405874729&fm=26&gp=0.jpg'
        },
        {
          name: 'van',
          src:
            'https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=3347120250,3273397972&fm=26&gp=0.jpg'
        },
        {
          name: 'walrus',
          src:
            'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=4182356470,1217302487&fm=26&gp=0.jpg'
        },
        {
          name: 'white',
          src: ''
        },
        {
          name: 'water',
          src:
            'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=253542072,1552330581&fm=26&gp=0.jpg'
        },
        {
          name: 'ax',
          src:
            'https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=220894649,489515145&fm=26&gp=0.jpg'
        },
        {
          name: 'OX',
          src:
            'https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=3372027488,3067456382&fm=26&gp=0.jpg'
        },
        {
          name: 'six',
          src:
            'https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=3860739180,3560138994&fm=26&gp=0.jpg'
        },
        {
          name: 'fox',
          src:
            'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=2075447594,3455328163&fm=26&gp=0.jpg'
        },
        {
          name: 'box',
          src:
            'https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=3954745134,1665351706&fm=26&gp=0.jpg'
        },
        {
          name: 'crocodile',
          src:
            'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=280047181,2014275669&fm=26&gp=0.jpg'
        },
        {
          name: 'alligator vs crocodile',
          src:
            'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=675415218,4263442820&fm=26&gp=0.jpg'
        },
        {
          name: 'koala',
          src:
            'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1287469270,132612785&fm=26&gp=0.jpg'
        },
        {
          name: 'snake',
          src:
            'https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=3306950495,1958980963&fm=26&gp=0.jpg'
        },
        {
          name: 'giraffe',
          src:
            'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=121061482,3818090745&fm=26&gp=0.jpg'
        },
        {
          name: 'elephant',
          src:
            'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=3617355398,3515251565&fm=26&gp=0.jpg'
        },
        {
          name: 'wolf',
          src:
            'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=506796049,495221894&fm=26&gp=0.jpg'
        },
        {
          name: 'kangaroo',
          src:
            'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=4031839428,1712857441&fm=26&gp=0.jpg'
        },
        {
          name: 'hippo',
          src:
            'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3742814689,902439556&fm=26&gp=0.jpg'
        },
        {
          name: 'toucan',
          src:
            'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=1527502285,2051950340&fm=26&gp=0.jpg'
        },
        {
          name: 'camel',
          src:
            'https://ss2.bdstatic.com/70cFvnSh_Q1YnxGkpoWK1HF6hhy/it/u=4164759291,1894308531&fm=26&gp=0.jpg'
        },
        {
          name: 'tiger',
          src:
            'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3735942298,3503547097&fm=26&gp=0.jpg'
        },
        {
          name: 'lion',
          src:
            'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=1918268271,3252067343&fm=26&gp=0.jpg'
        },
        {
          name: 'panda',
          src:
            'https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=316913143,2877943916&fm=26&gp=0.jpg'
        },
        {
          name: 'monkey',
          src:
            'https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=3025873168,2218343328&fm=26&gp=0.jpg'
        },
        {
          name: 'bear',
          src:
            'https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=3019630160,2963736585&fm=26&gp=0.jpg'
        }
      ]
    }
  },
  methods: {
    setC6() {
      this.list = this.c6
      this.title = 'U V W X - May'
      this.currentIndex = 0
    }
  }
}
